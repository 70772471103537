.App {
  width: 400px;
  height: 400px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

@media screen and (min-width: 320px) {
  .App {
    width: 300px;
    height: 300px;
  }
}

@media screen and (min-width: 425px) {
  .App {
    width: 400px;
    height: 400px;
  }
}
